

@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";


body {
	font-family: $font-family-sans-serif;
	background-color: #fff;

	position: relative;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba($black, .5);
		transition: .3s all ease;
		opacity: 0;
		visibility: hidden;
		z-index: 1;
	}
	&.offcanvas-menu {
		&:before {
			opacity: 1;
			z-index: 1002;
			visibility: visible;
		}
	}
}
p {
	color: darken(#ccc, 10%);
	font-weight: 300;
}
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: $font-family-sans-serif;
}
a {
	transition: .3s all ease;
	&, &:hover {
		text-decoration: none!important;
	}
}

.hero {
	height: 100vh;
	width: 100%;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}
.top-bar {
  padding: .5rem 0;
  background: #eee;
  a {
    color: lighten($black, 40%);
  }
}
.site-navbar {

	margin-bottom: 0px;
	z-index: 1999;
	position: relative;
	width: 100%;
	padding: 1rem;
	@include media-breakpoint-down(md) {
		height: 100px;
		padding-top: 0px;
		padding-left: 1rem;
	}
	.toggle-button {
		position: absolute;
		right: 0px;
	}
	.site-logo {
		position: absolute;
		// left: 15px;
		font-size: 2rem;
		text-transform: uppercase;
		font-weight: 900;
		line-height: 0;
		margin: 0;
		padding: 0;
		z-index: 3;
		

		@include media-breakpoint-down(md) {
			float: left;
			position: relative;
		}
	}
	
	
	.site-navigation {
		&.border-bottom {
			border-bottom: 1px solid lighten($gray-100, 80%)!important;
		}
		.site-menu {
			
			margin-bottom: 0;
			.active {
				color: $primary!important;
				// display: inline-block;
				// border-bottom: 2px solid $primary;
			}
			a {
				text-decoration: none!important;
				display: inline-block;
			}
			> li {
				display: inline-block;
				> a {
					margin-left: 15px;
					margin-right: 15px;
					padding: 20px 0px;
					color: $black!important;
					display: inline-block;
					text-decoration: none!important;
					// border-bottom: 2px solid transparent;
					
					&:hover {
						color: $primary;
						
					}
				}
			}
			.has-children {
				position: relative;
				> a {
					position: relative;
					padding-right: 20px;						
					&:before {
						position: absolute;
						content: "\e313";
						font-size: 16px;
						top: 50%;
						right: 0;
						transform: translateY(-50%);
						font-family: 'icomoon';
					}
				}
				.dropdown {
					visibility: hidden;
					opacity: 0;
					top: 100%;
					position: absolute;
					text-align: left;
					border-top: 2px solid $primary;
					box-shadow: 0 2px 10px -2px rgba(0,0,0,.1);
					padding: 0px 0;
					margin-top: 20px;
					margin-left: 0px;
					background: $white;
					transition: 0.2s 0s;
				
					&.arrow-top {
						position: absolute;

						&:before {
							display: none;
							bottom: 100%;
							left: 20%;
							border: solid transparent;
							content: " ";
							height: 0;
							width: 0;
							position: absolute;
							pointer-events: none;
						}
						&:before {
							border-color: rgba(136, 183, 213, 0);
							border-bottom-color: $white;
							border-width: 10px;
							margin-left: -10px;
						}	
					}
					

					a {
						// font-size: 16px;
						text-transform: none;
						letter-spacing: normal;
						transition: 0s all;
						color: $black!important;
						&.active {
							color: $primary!important;
							background: $gray-100;
						}
					}
					// .active {
					// 	> a {
					// 		color: $primary!important;
					// 	}
					// }
					> li {
						list-style: none;
						padding: 0;
						margin: 0;
						min-width: 200px;
						> a {
							padding: 9px 20px;
							display: block;
							&:hover {
								background: $gray-100;
								color: $gray-400;
							}
						}

						&.has-children {
							> a {
								&:before {
									content: "\e315";
									right: 20px;
								}
							}
							> .dropdown, > ul {
								left: 100%;
								top: 0;
							}
							&:hover, &:active, &:focus {
								> a {
									background: $gray-100;
									color: $gray-400;
								}
							}
						}
					}
				}
				
				&:hover, &:focus, &:active {
					> a {
						color: $primary;
					}
				}
				&:hover, &:focus, &:active {
					cursor: pointer;
					> .dropdown {
						transition-delay: 0s;
						margin-top: 0px;
						visibility: visible;
						opacity: 1;

				
					}
				}
			}
		}
	}
}


// mobile menu

.site-mobile-menu {
	width: 300px;
	position: fixed;
	right: 0;
	z-index: 2000;
	padding-top: 20px;
	background: $white;
	height: calc(100vh);
	
	transform: translateX(110%);
	box-shadow: -10px 0 20px -10px rgba(0,0,0,.1);
	transition: .3s all ease-in-out;

	.offcanvas-menu & {
		transform: translateX(0%);
	}

	.site-mobile-menu-header {
		width: 100%;
		float: left;
		padding-left: 20px;
		padding-right: 20px;
		
		.site-mobile-menu-close {
			float: right;
			margin-top: 8px;
			span {
				font-size: 30px;
				display: inline-block;
				padding-left: 10px;
				padding-right: 0px;
				line-height: 1;
				cursor: pointer;
				transition: .3s all ease;
				&:hover {
					color: $gray-400;
				}
			}
		}
		.site-mobile-menu-logo {
			float: left;
			margin-top: 10px;
			margin-left: 0px;
			a {
				display: inline-block;
				text-transform: uppercase;
				img {
					max-width: 70px;
				}
				&:hover {
					text-decoration: none;
				}
			}
		}
	}

	.site-mobile-menu-body {
		overflow-y: scroll;
  	-webkit-overflow-scrolling: touch;
		position: relative;
		padding: 0 20px 20px 20px;

		height: calc(100vh - 52px);
		padding-bottom: 150px;

	}
	.site-nav-wrap {
		padding: 0;
		margin: 0;
		list-style: none;
		// float: left;

		// width: 100%;
		// height: 100%;
		position: relative;

		a {
			padding: 10px 20px;
			display: block;
			position: relative;
			color: $gray-900;
			&:hover {
				color: $primary;
			}
		}
		li {
			position: relative;
			display: block;
			.nav-link {
				&.active {
					color: $primary;
				}
			}
			// &.active {
			// 	> a {
			// 		color: $primary;
			// 	}
			// }
		}
		.arrow-collapse {
			position: absolute;
			right: 0px;
			top: 10px;
			z-index: 20;
			width: 36px;
			height: 36px;
			text-align: center;
			cursor: pointer;
			border-radius: 50%;
			&:hover {
				background: $gray-100;
			}
			&:before {
				font-size: 12px;
				z-index: 20;
				font-family: "icomoon";
				content: "\f078";
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%) rotate(-180deg);
				transition: .3s all ease;
			}
			&.collapsed {
				&:before {
					transform: translate(-50%, -50%);	
				}
			}
		}
		> li {
			display: block;
			position: relative;
			float: left;
			width: 100%;
			
			> a {
				padding-left: 20px;
				font-size: 20px;
			}
			> ul {
				padding: 0;
				margin: 0;
				list-style: none;
				> li {
					display: block;
					> a {
						padding-left: 40px;
						font-size: 16px;
					}
					> ul {
						padding: 0;
						margin: 0;
						> li {
							display: block;
							> a {
								font-size: 16px;
								padding-left: 60px;			
							}
						}
					}
				}
			}
		}
	
	&[data-class="social"] {
		float: left;
		width: 100%;
		margin-top: 30px;
		padding-bottom: 5em;
		> li {
			width: auto;
			&:first-child {
				a {
					padding-left: 15px!important;
				}
			}
		}
	}

	}


}


.sticky-wrapper {
	position: relative;
	z-index: 100;
	width: 100%;
	& + .site-blocks-cover {
		// margin-top: 140px;
		// margin-top: 96px;
	}
	.site-navbar {
		transition: .3s all ease;
	}
	.site-navbar {
		background: $white;
		// border-bottom: 1px solid rgba($white, .2);
		.site-logo {
			a {
				color: $white;
			}
		}
		ul {
			li {
				a {
					color: rgba($white, .7)!important;
					&.active {
						color: $white!important;
					}
				}
			}
		}
	}	
	&.is-sticky {
		
		.site-navbar {
			background: $white;
			// border-bottom: 1px solid transparent;
			box-shadow: 4px 0 20px -5px rgba(0,0,0,.2);
			.site-logo {
				a {
					color: $primary;
				}
			}
			ul {
				li {
					a {
						color: $black!important;
						&.active {
							color: $primary!important;
						}
					}
				}
			}
		}
	}
	.shrink {
		padding-top: 0px!important;
		padding-bottom: 0px!important;
		@include media-breakpoint-down(md) {
			padding-top: 30px!important;
			padding-bottom: 30px!important;
		}
	}
}

